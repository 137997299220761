import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgDetective from "../images/detective.jpg"
import imgScales from "../images/scales.jpg"
import imgRisk from "../images/risk.jpg"
import imgBusPass from "../images/bus-pass.jpg"
import imgNotFlexible from "../images/not-flexible.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="8 reasons standing orders are bad for business." />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">8 reasons standing orders are bad for business</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">8 reasons standing orders are bad for business</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>If you’re still using standing orders or bank transfers to collect payment in volume for your home-to-school bus services, you might be interested to know there are other simpler ways available that can help ensure travel is paid for on time and without the detective work of figuring out who has or has not paid each month. </p>
                    <p>Standing orders are traditionally seen as a straight forward way to transfer money. The single biggest benefit is the lack of transaction fee involved, although this is often a false economy as it tends to create costs that are largely hidden to the business. The admin involved in regularly ensuring all customers are paying is significant, but it also reduces available time to that could otherwise be spent helping grow the business - representing a double loss.</p>
                    <p>Based on our experience of working with transport operators, we’ve summarised why standing orders are a poor choice for collecting payment from parents for your school bus.</p>
                  </div>
                  <div className='article-inner'>

                    <h2>1. Detective work finding out who has paid</h2>
                    <p><img src={imgDetective} alt="" /></p>
                    <p>It’s an unenviable job, but with standing orders, someone has to do it. Reviewing the bank statements each month, matching up payment reference ids with a spreadsheet, figuring out who then isn’t on the bank statement.</p>
                    <p>Naturally, not all parents will get this right, and some customers will end up putting an incorrect reference id on payments or even none at all, making the process even more painful than what it already is.</p>
                    <p>It’s also prone to human error. If you get it wrong, you’ll either hand out free travel or you’ll irritate customers by mistakenly accusing them of non-payment.</p>

                    <h2>2. It relies on the customer setting it up in time</h2>
                    <p>It all begins with that first payment. In an ideal world, you want your payment in the bank for September before the passenger has stepped aboard any of your services. Instead, we hear all too often from operators that rely on standing orders and direct bank transfers, it can take weeks and even months to fully sort out. Repeated phone calls to parents who promise to get right onto it, only to become distracted and then forget until the next phone call.</p>

                    <h2>3. Failed payments easily go unnoticed</h2>
                    <p>Lacking any form of direct notification, failed payments would go totally unnoticed without a very keen eye constantly monitoring bank statements. Even with your most trusted customers, any given month a payment could suddenly fail due to lack of funds in their account, and you nor the customer would know until it’s too late.</p>

                    <h2>4. It leaves you at total mercy of the customer</h2>
                    <p><img src={imgScales} alt="" /></p>
                    <p>Whilst we hope all of your customers are organised and reliable, the reality is that parents are busy people. You may also have your fair share of difficult customers to deal with too - the ones you know by name. If the responsibility is completely on the customer to provide payment manually, this makes collecting payments at scale (from dozens to hundreds or even thousands of customers) each month a real challenge.</p>
                    <p>What’s more, when payments are actually set up, there’s no guarantee that will continue to be the case. This automatically creates an extra job for your team every single month. It’s a situation that can often leave you as an operator feeling powerless and with no control.</p>

                    <h2>5. Customers not cancelling standing orders</h2>
                    <p>At the opposite end of the spectrum, you then have customers that pay too much. It’s surprisingly common and something we’ve heard happens often with school leavers. It also happened a lot during the first national lockdown when schools were closed down, with parents leaving their standing orders in place despite warnings from the operator to stop them.</p>
                    <p>Inevitably, this leads to some very confusing and even frustrating conversations with parents when they suddenly realise they’ve been paying for services they haven’t used. Some customers may be quick to point the blame, but ultimately if they are in complete control of the payments, they’ve just generated extra admin for you to deal with at your expense.</p>

                    <h2>6. They’ve stopped paying but have they stopped travelling?</h2>
                    <p><img src={imgBusPass} alt="" /></p>
                    <p>We’ve heard of customers demanding refunds from operators for unused travel and in some cases holding back funds. Again, with standing orders the customer is in control, which makes it possible for them to use future payments as ransom. </p>
                    <p>Many operators use simple plastic passes as forms of ID that only involves a visual check from the driver. The big problem this creates is the huge void of data that isn't captured. As an operator, you have no evidence who has boarded or when, which then makes it impossible to reason with customers in such a situation. </p>
                    <p>The biggest risk however, is once a customer has stopped paying, there’s no guarantee that they’ve stopped travelling. Although operators mandate that passes need to be handed back, we know from experience this rarely happens, which means the pass is likely still in circulation and you would never know.</p>

                    <h2>7. It’s not flexible</h2>
                    <p><img src={imgNotFlexible} alt="" /></p>
                    <p>Standing orders are very rigid which don’t always align with the reality of operating home-to-school services. Payments may need to go up when passengers want to use a different route or bus stop mid year or their travel needs increase (e.g. upgrade from AM only to return journeys). Payments may need to reduce or even stop as we’ve seen with Covid-19. You may also have one off payments that need to be taken such as replacement pass fees or deposits.</p>
                    <p>All of these examples will generate further admin when you’re not in control of the payment; a process of asking the customer to do something, frequently checking to see if they have completed what you asked, chasing the customers when you realise they have not done as asked and then starting this cycle over again until eventually reaching the desired outcome. The more customers you have, the more painful and costlier this all becomes.</p>

                    <h2>8. There’s no protection for the customer</h2>
                    <p><img src={imgRisk} alt="" /></p>
                    <p>From a customer's standpoint there is also genuine risk involved from wiring money directly to your bank account - it offers no financial protection. In these uncertain times, consumers should be seeking to minimise risk. You may have heard the horror stories over the years with operators taking the annual payment up front, then suddenly going out of business and leaving parents significantly out of pocket.</p>
                    <p>For larger payments such as annual or term, paying by credit card protects the customer in the event the business goes bust. For smaller monthly payments, direct debit offers protection in the form of the Direct Debit guarantee, safeguarding customers against errors or fraud.</p>

                    <h2>"But we’ve always done it this way"</h2>
                    <p>The pain of managing payments from parents using standing orders is all too common amongst transport operators and we’ve built a system that helps give you back control but in a simple way that will significantly reduce admin. ShuttleID replaces standing orders with direct debit and card payments. Here’s why:</p>
                    <ul style={{ width: 'auto' }}>
                      <li><strong>No more detective work.</strong> With direct debit, you’re informed instantly when payments fail or mandates are cancelled, as is the customer. Remove the guesswork.</li>
                      <li><strong>Customers are on track from day one.</strong> Avoid operating at a loss. Tickets are automatically distributed only when payment is set up.</li>
                      <li><strong>You can’t miss a failed payment.</strong> Failed payment notifications remove the job of trawling through bank statements each month. What’s more, hitting a retry button is all you need to do to make sure the payment goes through the next time.</li>
                      <li><strong>It puts operators back in control.</strong> Customers don’t get a bus pass until they’ve set up their direct debit, which is done completely online and without involving your staff. You have full visibility of all payments and the control to change it whenever required.</li>
                      <li><strong>Customers can’t overpay.</strong> With fixed payment terms set on the ticket, you’re in complete control of when the monthly payments start and finish, avoiding accidental overpayment. </li>
                      <li><strong>Stop free travel.</strong> As the payments are directly linked to a bus pass in our system, if a customer stops paying you can void their pass by pressing a button. Drivers will see the pass is invalid and you’ll also be able to verify in the back office when it was used.</li>
                      <li><strong>Flexible.</strong> With a direct debit mandate, effectively you have full permission to take funds when you need to. This simplifies the job of amending payment schedules or taking one-off payments.</li>
                      <li><strong>Protects customers.</strong> The direct debit guarantee protects customers from fraudulent charges and card payments can protect high value transactions in the event an operator goes out of business. It’s a safer way to pay.</li>
                    </ul>

                    <p>If you think it’s time to review the hidden cost of standing orders in your business, <Link to="/#contact">get in touch</Link> today for an easier way to manage payments from parents. It can take as little as 24 hours to get set up and it’s available to operators of all sizes. </p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID, a cost effective cashless ticketing system designed to make managing home to school services simple.</p>
                        <p style={{ marginBottom: 10 }}>During a 14 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home to school first hand, leading to the launch of ShuttleID.</p>
                        <p style={{ margin: 0 }}>Email: <a href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage